@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
body {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::-webkit-scrollbar {
  background-color: #1a5be022;
  width: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(26, 92, 224, 0.559);
  border-radius: 5px;
}

.bn-container {
  display: flex !important;
  flex-direction: column-reverse !important;
}

/* .bn-container > div[data-floating-ui-focusable] {
  position: relative !important;
  transform: translate(0, 0) !important;
} */

.bn-editor {
  background: #9900 !important;
  padding-top: 0px !important;
  margin-top: 20px !important;
  padding-left: 8px !important;
}

.bn-toolbar {
  box-shadow: none !important;
}
